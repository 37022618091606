<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <ValidationObserver ref="form">
        <form @submit.prevent="onSubmit">
          <login-card header-color="indigo">
            <h4 slot="title" class="title">Esqueci o Password</h4>
            <p slot="description" class="description">...</p>
            <div class="md-layout md-alignment-center-center" slot="inputs">
              <div class="md-layout-item md-size-25" v-show="load">
                <md-progress-spinner
                  md-mode="indeterminate"
                ></md-progress-spinner>
              </div>

              <ValidationProvider
                name="e-mail"
                rules="required|email"
                v-slot="{ errors }"
                v-show="!load"
                class="md-layout-item md-size-100"
              >
                <md-field
                  class="md-form-group md-invalid"
                  style="margin-bottom: 2rem"
                >
                  <md-icon>email</md-icon>
                  <label>Email</label>

                  <md-input v-model="user.email" />
                  <validation-error :errors="errors" />
                </md-field>
              </ValidationProvider>
            </div>
            <div class="button-container" slot="footer">
              <md-button class="md-indigo md-round mt-4" type="submit">
                Enviar E-mail
              </md-button>
            </div>
          </login-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { LoginCard, ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import Vue from "vue";

export default {
  components: {
    LoginCard,
    ValidationError
  },
  mixins: [formMixin],
  data() {
    return {
      user: {
        email: null
      },
      load: false
    };
  },
  created: function() {},
  methods: {
    async resetPassword() {
      this.load = true;
      try {
        await Vue.$http.post(`v1/forgot-password`, { email: this.user.email });
        await this.$store.dispatch(
          "alerts/success",
          "Verifique sua caixa de email."
        );
        this.load = false;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e.response.data.message);
        this.load = false;
      }
    },
    async alert(message) {
      await this.$store.dispatch("alerts/error", message);
    },
    async onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          let values = Object.values(this.$refs.form.errors);
          values.forEach(value => {
            value.forEach(message => {
              this.alert(message);
            });
          });
          return;
        }

        this.resetPassword();
        // Resetting Values
        //this.firstName = this.lastName = this.email = "";

        // Wait until the models are updated in the UI
        this.$nextTick(() => {
          //this.$refs.form.reset();
        });
      });
    }
  }
};
</script>
